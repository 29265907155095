.blinking{
    animation:blinkingText 1.2s infinite;
}
.loading-page{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}
@keyframes blinkingText{
    0%{     color: #fd6c6c;    }
    49%{    color: #fd6c6c; }
    60%{    color: transparent; }
    99%{    color: #fd6c6c;  }
    100%{   color: #fd6c6c;    }
}